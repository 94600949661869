<template>
	<div class="hx-order-two">
		<!-- 信息 -->
		<div align="left" class="left">
			<div id="ins">以下七类任选其中一类,且不能多选也不能全部空选</div>
			<div id="caiye">点击<a href="./cichu.pdf" target="_blank">此处</a>下载电子版瓠犀产品彩页</div>
			<table style="width:100%;height: 450px;" cellpadding="0" cellspacing="0">
				
				<tr>
					<td align="right">
						<span style="font-size: 18px;font-weight: bold;padding-right: 10px;">正畸保持器A类</span></td>
					<td>
						<el-select @clear="zhengJiClear" @change="selectZhengJi" :disabled="zhengjidis" clearable v-model="zhengji" multiple placeholder="请选择">
							<el-option v-for="item in zhengJiOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				<tr>
					<td align="right">
						<span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;">
							<span style="font-size: 18px;font-weight: bold;">矫治器B类</span>
							<span style="font-weight: normal;font-size: 14px;letter-spacing:2px;">(功能矫治器类)</span>
							</span>
						</td>
					<td>
						<el-select @clear="gongNengClear" @change="selectGongNeng" :disabled="gongnengdis" clearable v-model="gongneng" multiple placeholder="请选择">
							<el-option v-for="item in gongNengJiaoZhiOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				<tr>
					<td align="right">
						<span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;">
							<span>矫治器C类</span>
							<span style="font-weight: normal;font-size: 14px;letter-spacing:2px;">(螺旋扩大器类)</span>
						</span>
					</td>
					<td>
					 <el-select @clear="luoXuanClear" @change="selectLuoXuan" :disabled="luoxuandis" clearable v-model="luoxuan" multiple placeholder="请选择">
						<el-option v-for="item in luoXuanKuoDaQiOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					</td>
				</tr>
				<tr>
					<td align="right"><span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;"><span>矫治器D类</span><span style="font-weight: normal;font-size: 14px;letter-spacing:2px;">(美白/阻鼾器/夜磨牙垫类)</span></span></td>
					<td>
						<el-select @clear="yadianClear" @change="selectYaDian" :disabled="yadiandis" clearable v-model="yadian" multiple placeholder="请选择">
							<el-option v-for="item in yaDianOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				
				<tr>
					<td align="right"><span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;"><span>矫治器E/F类</span><span style="font-weight: normal;font-size: 14px;letter-spacing:2px;">(其他类)</span></span></td>
					<td>
						<el-select @clear="qiTaClear" @change="selectQiTa" :disabled="qitadis" clearable v-model="qita" multiple placeholder="请选择">
							<el-option v-for="item in qiTaOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				<tr>
					<td align="right"><span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;"><span>矫治器G类</span><span style="font-weight: normal;font-size: 14px;letter-spacing:2px;">(FACE正畸专业颌板)</span></span></td>
					<td>
						<el-select @clear="heBanClear" @change="selectHeBan" :disabled="hebandis" clearable v-model="heban" multiple placeholder="请选择">
							<el-option v-for="item in heBanOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				
				<!-- 矫治器H类(运动护齿类)开始 -->
				<tr>
					<td align="right"><span style="font-size: 18px;font-weight: bold;padding-right: 10px;display: flex;flex-direction: column;"><span>运动护齿H类</span></span></td>
					<td>
						<el-select @clear="yunDongClear" @change="selectYunDong" :disabled="yundongdis" clearable v-model="yunDong" multiple placeholder="请选择">
							<el-option v-for="item in yunDongOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
				<!-- 矫治器H类(运动护齿类)结束 -->
				<tr>
					<td  style="border-top:2px solid gray ;" align="right"><span style="font-size: 18px;font-weight: bold;padding-right: 10px;">基托主颜色<span style="color: red;">*</span></span></td>
					<td  style="border-top:2px solid gray ;">
						<el-select @change="selectJiTuo" clearable v-model="jituo" multiple placeholder="请选择">
							<el-option v-for="item in jiTuoOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</td>
				</tr>
			</table>
			
			
		</div>
		<!-- 右边 -->
		<div class="right" align="left">
			
		       <!-- <div v-show="status<2"> -->
				<div v-show="status==1||status==0">
				   <span>请具体说明需要治疗什么?<span style="color: red;">*</span></span>
				   <div  id="zlcon" style="margin-top: 0.625rem;z-index: 0;"></div>
				   <span><span style="color: red;">*</span>为必填项&nbsp;<span style="color: red">(编辑内容不能超过110个字)</span></span>
					<span class="wordNumber">{{TiLength}}/110</span>
				   <div align="right" style="margin-top: 20px;">
				   	<el-button class="back" type="primary" @click="back">返回</el-button>
				   	<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
				   </div>
			   </div>
			   <div v-show="status==2">
			     	<span style="font-weight: bolder;">原始治疗方案:</span>
					<p style="font-weight: normal;padding-left: 10px;width: 413px;" v-html="planDoctor">{{planDoctor}}</p>
			   </div>
			   <div v-if="showWenzi" style="color: green;font-weight: bolder;margin-bottom: 10px;">管理员已同意该治疗方案</div>
			<div class="aaa"  v-show="status==2" style="min-height: 400px;width: 510px;" align="left">
				<div :class="{ccc:item.answered}"  v-for="(item,index) in zhiLiaoMuBiao"
					style="text-align: left;border-radius: 5px;width: 25rem;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 10px;margin-bottom: 5px;">
					<p style="font-weight: bolder;color: #F18F57;">{{item.title}}:&nbsp;&nbsp;<span style="font-weight: normal;">{{item.createdTime}}</span></p>
					<p style="color: #F18F57;">{{item.content}}</p>
					<p style="font-weight: bolder;" v-if="item.answered==true">医师反馈结果</p>
					<p v-if="item.answered==true && item.answer!='ok'">不同意:&nbsp;&nbsp;{{item.createdTime}}</p>
					<p v-if="item.answered==true && item.answer=='ok'">已同意:&nbsp;&nbsp;{{item.createdTime}}</p>
					<p v-if="item.answered==true && item.answer!='ok'">不同意原因:&nbsp;&nbsp;{{item.answer}}</p>
				</div>
				
				
				<div id="" v-show="showTongyi">
					<div style="display: flex;padding-top: 20px;">
						<el-button style="margin-left: 300px;" class="nextStep" type="primary" @click="tongyi">同意</el-button>
					</div>
					<div style="margin: 0px;">不同意，并请填写原因</div>
					<div id="zlcon1" style="margin-top: 5px;z-index: 0; width: 420px !important;height: 200px!important;">
					</div>
					<div style="display: flex;justify-content: space-between;align-items: center;width: 420px">
						<div style="width: 200px;"><span style="color: red;display: inline-block;" v-show="showBTYWarn">编辑内容不能超过35个字</span></div>
						<span>{{BuTongYiwordNumber}}/35</span>
					</div>
					
					<div style="display: flex;padding-top: 20px;">
						<!-- <el-button style="margin-left: 50px;" class="nextStep" type="primary" @click="tongyi">同意</el-button> -->
						<el-button style="margin-left: 300px;" class="nextStep" type="primary" @click="notongyi">提交</el-button>
					</div>
				</div>
			</div>	
			
			
		</div>
	</div>
</template>

<script>
	import E from 'wangeditor';
	import EE from 'wangeditor';
	export default {
		data: function() {
			return {
				TiLength:0,
				BuTongYiwordNumber:0,
				showBTYWarn:false,
				warnShow:false,
				showWenzi:false,
				showTongyi:true,
				yundongdis:false,
				gongnengdis:false,
				zhengjidis:false,
				yadiandis:false,
				qitadis:false,
				hebandis:false,
				luoxuandis:false,
				planDoctor:'',
				mxchecked: false,
				zjchecked: false,
				editor: '',
				editor1: '',
				ruleForm: {
					oid: '',
					planDoctor: '',
					jituo:[],
					zhengji:[],
					gongneng:[],
					yadian:[],
					qita:[],
					heban:[],
					luoxuan:[],
					huchi:[]
				},
				zhiLiaoMuBiao: [],
				status: 0,
				yunDongOptions: [{
						value: "H-0001",
						label: "鲜红 H-0001"
					},
					{
						value: "H-0002",
						label: "鲜黄 H-0002"
					},
					{
						value: "H-0003",
						label: "鲜蓝 H-0003"
					},
					{
						value: "H-0004",
						label: "鲜绿 H-0004"
					},
					{
						value: "H-0005",
						label: "浅蓝 H-0005"
					},
					{
						value: "H-0006",
						label: "银色 H-0006"
					},
					{
						value: "H-0007",
						label: "深红 H-0007"
					},
					{
						value: "H-0008",
						label: "黑蓝 H-0008"
					},
					{
						value: "H-0009",
						label: "鲜粉 H-0009"
					},
					{
						value: "H-0010",
						label: "纯白 H-0010"
					},
					{
						value: "H-0011",
						label: "深黑 H-0011"
					},
					{
						value: "H-0012",
						label: "金色 H-0012"
					},
					{
						value: "H-0013",
						label: "伪装 H-0013"
					},
					{
						value: "H-0014",
						label: "熔岩 H-0014"
					},
					{
						value: "H-0015",
						label: "彩带 H-0015"
					},
					{
						value: "H-0016",
						label: "彩虹 H-0016"
					},
					{
						value: "H-0017",
						label: "斑马 H-0017"
					}
				
				],
				yunDong:[],
				jiTuoOptions: [{
						value: "No",
						label: "不需要基托"
					},
					{
						value: "Clear",
						label: "无色"
					},
					{
						value: "Red",
						label: "红色"
					},
					{
						value: "Yellow",
						label: "黄色"
					},
					{
						value: "Blue",
						label: "蓝色"
					},
					{
						value: "Green",
						label: "绿色"
					},
					{
						value: "Purple",
						label: "紫色"
					},
					{
						value: "Neon-Yellow",
						label: "霓虹黄"
					},
					{
						value: "Any",
						label: "我不在乎颜色可任意"
					}

				],
				jituo: [],
				/* 正畸 */
				zhengJiOptions: [{
						value: "A-0001",
						label: "比格保持器 A-0001"
					},
					{
						value: "A-0002",
						label: "霍利式保持器 A-0002"
					},
					{
						value: "A-0003",
						label: "霍利式保持器(焊接式) A-0003"
					},
					{
						value: "A-0004",
						label: "压膜保持器 A-0004"
					},
					{
						value: "A-0005",
						label: "南丝腭弓 A-0005"
					},
					{
						value: "A-0006",
						label: "舌弓 A-0006"
					},
					{
						value: "A-0007",
						label: "横腭杆 A-0007"
					},
					{
						value: "A-0008",
						label: "间隙保持器 A-0008"
					},
					{
						value: "A-0009",
						label: "舌侧固定保持器(麻花丝) A-0009"
					},
					{
						value: "A-0010",
						label: "顺其保持器OTP A-0010"
					}

				],
				zhengji: [],
				/* 功能矫治器类 */
				gongNengJiaoZhiOptions: [{
						value: "B-0001",
						label: "法兰克功能矫治器I B-0001"
					},
					{
						value: "B-0002",
						label: "法兰克功能矫治器II B-0002"
					},
					{
						value: "B-0003",
						label: "法兰克功能矫治器III B-0003"
					},
					{
						value: "B-0004",
						label: "肌激动器 B-0004"
					},
					{
						value: "B-0005",
						label: "改良型肌激动器 B-0005"
					},
					{
						value: "B-0006",
						label: "改良型肌激动器 B-0006"
					},
					{
						value: "B-0007",
						label: "平面导板 B-0007"
					},
					{
						value: "B-0008",
						label: "平面导板(固定式) B-0008"
					},
					{
						value: "B-0009",
						label: "斜面导板 B-0009"
					},
					{
						value: "B-0010",
						label: "前庭盾 B-0010"
					},
					{
						value: "B-0011",
						label: "弹簧矫正器 B-0011"
					},
					{
						value: "B-0012",
						label: "习惯破除器 B-0012"
					},
					{
						value: "B-0013",
						label: "舌档 B-0013"
					},
					{
						value: "B-0014",
						label: "舌刺(固定) B-0014"
					},
					{
						value: "B-0015",
						label: "膜片式正位器PR B-0015"
					},
					{
						value: "B-0016",
						label: "舌体抬高器TE B-0016"
					}
				],
				gongneng: [],
				/* 螺旋扩大器类 */
				luoXuanKuoDaQiOptions: [{
						value: "C-0001",
						label: "上颌快速扩弓(螺旋式) C-0001"
					},
					{
						value: "C-0002",
						label: "上颌快速扩弓(螺旋式)带面弓勾 C-0002"
					},
					{
						value: "C-0003",
						label: "上颌快速扩弓(记忆型8mm) C-0003"
					},
					{
						value: "C-0004",
						label: "上颌快速扩弓(记忆型10mm) C-0004"
					},
					{
						value: "C-0005",
						label: "四眼簧扩弓器(舌侧焊接) C-0005"
					},
					{
						value: "C-0006",
						label: "四眼簧扩弓器(舌侧扣) C-0006"
					},
					{
						value: "C-0007",
						label: "骨性局部螺旋器 C-0007"
					},
					{
						value: "C-0008",
						label: "通用型扩弓器 C-0008"
					},
					{
						value: "C-0009",
						label: "通用型扩弓器11mm C-0009"
					},
					{
						value: "C-0010",
						label: "横向记忆扩弓器 C-0010"
					},
					{
						value: "C-0011",
						label: "三维扩弓器(螺旋式) C-0011"
					},
					{
						value: "C-0012",
						label: "三维扩弓器(记忆式) C-0012"
					},
					{
						value: "C-0013",
						label: "扇形扩弓器(一体式) C-0013"
					},
					{
						value: "C-0014",
						label: "扇形扩弓器(固定式) C-0014"
					},
					{
						value: "C-0015",
						label: "扇形扩弓器(分离式) C-0015"
					},
					{
						value: "C-0016",
						label: "下颌弓型扩弓器 C-0016"
					},
					{
						value: "C-0017",
						label: "舌侧舒适扩大螺旋器(8mm) C-0017"
					},
					{
						value: "C-0018",
						label: "舌侧舒适扩大螺旋器(12mm) C-0018"
					},
					{
						value: "C-0019",
						label: "舌侧常规扩大螺旋器(8mm) C-0019"
					},
					{
						value: "C-0020",
						label: "磨牙7颊侧直立矫正器 C-0020"
					},
					{
						value: "C-0021",
						label: "双导面功能矫治器TwinBlock(不可调) C-0021"
					},
					{
						value: "C-0022",
						label: "双导面功能矫治器TwinBlock(螺旋式可调) C-0022"
					},
					{
						value: "C-0023",
						label: "磨牙远移基托式(青蛙装置I) C-0023"
					},
					{
						value: "C-0024",
						label: "磨牙远移支抗钉式(青蛙装置II) C-0024"
					},
					{
						value: "C-0025",
						label: "钟摆矫治器(TMA丝) C-0025"
					},
					{
						value: "C-0026",
						label: "SanderII导下颌向前(非记忆型) C-0026"
					},
					{
						value: "C-0027",
						label: "SanderII导下颌向前(记忆型) C-0027"
					},
					{
						value: "C-0028",
						label: "SanderII导下颌向后 C-0028"
					},
					{
						value: "C-0029",
						label: "II类功能矫治器(基托式) C-0029"
					},
					{
						value: "C-0030",
						label: "Herbst咬合前移器(钢铸件) C-0030"
					},
					{
						value: "C-0031",
						label: "Williams咬合前移器颌间可调(带环式) C-0031"
					},
					{
						value: "C-0032",
						label: "Williams咬合前移器颌间可调(钢铸件) C-0032"
					},
					{
						value: "C-0033",
						label: "FMA下颌功能推进器(钢铸件) C-0033"
					},
					{
						value: "C-0034",
						label: "舌簧合垫 C-0034"
					},
					{
						value: "C-0035",
						label: "顺其II C-0035"
					},
					{
						value: "C-0036",
						label: "顺其III C-0036"
					}

				],
				luoxuan: [],
				/* 牙垫 */
				yaDianOptions: [{
						value: "D-0001",
						label: "美白托盘(膜片) D-0001"
					},
					{
						value: "D-0002",
						label: "阻鼾器(高端，锁扣可调式)停产因没有配件 D-0002"
					},
					{
						value: "D-0003",
						label: "阻鼾器(停产因没有配件) D-0003"
					},
					{
						value: "D-0004",
						label: "夜磨牙合垫(软膜片) D-0004"
					},
					{
						value: "D-0005",
						label: "夜磨牙合垫(硬膜片) D-0005"
					},
					{
						value: "D-0006",
						label: "夜磨牙合垫(软/硬双面膜片) D-0006"
					}
				],
				yadian:[],
				/* EF其他类别 */
				qita:[],
				qiTaOptions:[
					{
						value:"E-0001",
						label:"正畸寄存模型 E-0001"
					},
					{
						value:"F-0001",
						label:"唇侧间接粘接 F-0001"
					},
					{
						value:"F-0002",
						label:"唇侧间接粘接 F-0002"
					}
				],
				/* FACE正畸专业颌板 */
				heban:[],
				heBanOptions:[
					{
						value:"G-0001",
						label:"颌板(膜片) G-0001"
					},
					{
						value:"G-0002",
						label:"颌板(树脂) G-0002"
					}
				]
			}
		},
		methods: {
			//判断选择的基托是不是不需要基托
			selectJiTuo(value){
				if(value.indexOf('No')!=-1){
					
					this.jiTuoOptions=[{
						value: "No",
						label: "不需要基托"
					}];
					this.jituo=["No"];
				}else{
					this.jiTuoOptions=[{
						value: "No",
						label: "不需要基托"
					},
					{
						value: "Clear",
						label: "无色"
					},
					{
						value: "Red",
						label: "红色"
					},
					{
						value: "Yellow",
						label: "黄色"
					},
					{
						value: "Blue",
						label: "蓝色"
					},
					{
						value: "Green",
						label: "绿色"
					},
					{
						value: "Purple",
						label: "紫色"
					},
					{
						value: "Neon-Yellow",
						label: "霓虹黄"
					},
					{
						value: "Any",
						label: "我不在乎颜色可任意"
					}
				
				]
				}
				
			},
			//选择运动
			selectYunDong(value){
				if(this.yunDong.length==0){
					this.gongnengdis=false;
					this.yadiandis=false;
					this.qitadis=false;
					this.hebandis=false;
					this.luoxuandis=false;
					this.zhengjidis=false;
					
				}else{
				this.gongnengdis=true;
				this.yadiandis=true;
				this.qitadis=true;
				this.hebandis=true;
				this.luoxuandis=true;
				this.zhengjidis=true;
				}
			},
			//运动清空
			yunDongClear(){
				this.gongnengdis=false;
				this.yadiandis=false;
				this.qitadis=false;
				this.hebandis=false;
				this.luoxuandis=false;
				this.yundongdis=false;
				this.zhengjidis=false;
			},
			//选择正畸
			selectZhengJi(value){
				if(this.zhengji.length==0){
					this.gongnengdis=false;
					this.yadiandis=false;
					this.qitadis=false;
					this.hebandis=false;
					this.luoxuandis=false;
					this.yundongdis=false;
				}else{
				this.gongnengdis=true;
				this.yadiandis=true;
				this.qitadis=true;
				this.hebandis=true;
				this.luoxuandis=true;
				this.yundongdis=true;
				}
			},
			//正畸清空
			zhengJiClear(){
				this.gongnengdis=false;
				this.yadiandis=false;
				this.qitadis=false;
				this.hebandis=false;
				this.luoxuandis=false;
				this.yundongdis=false;
			},
			//选择功能
			selectGongNeng(value){
				if(this.gongneng.length==0){
					this.zhengjidis=false;
					this.yadiandis=false;
					this.qitadis=false;
					this.hebandis=false;
					this.luoxuandis=false;
					this.yundongdis=false;
				}else{
				this.zhengjidis=true;
				this.yadiandis=true;
				this.qitadis=true;
				this.hebandis=true;
				this.luoxuandis=true;
				this.yundongdis=true;
				}
			},
			//功能清空
			gongNengClear(){
				this.zhengjidis=false;
				this.yadiandis=false;
				this.qitadis=false;
				this.hebandis=false;
				this.luoxuandis=false;
				this.yundongdis=false;
			},
			//选择螺旋
			selectLuoXuan(value){
				if(this.luoxuan.length==0){
					this.zhengjidis=false;
					this.yadiandis=false;
					this.qitadis=false;
					this.hebandis=false;
					this.gongnengdis=false;
					this.yundongdis=false;
				}else{
				this.zhengjidis=true;
				this.yadiandis=true;
				this.qitadis=true;
				this.hebandis=true;
				this.gongnengdis=true;
				this.yundongdis=true;
				}
			},
			//螺旋清空
			luoXuanClear(){
				this.zhengjidis=false;
				this.yadiandis=false;
				this.qitadis=false;
				this.hebandis=false;
				this.gongnengdis=false;
				this.yundongdis=false;
			},
			//选择牙垫
			selectYaDian(value){
				if(this.yadian.length==0){
					this.zhengjidis=false;
					this.luoxuandis=false;
					this.qitadis=false;
					this.hebandis=false;
					this.gongnengdis=false;
					this.yundongdis=false;
				}else{
				this.zhengjidis=true;
				this.luoxuandis=true;
				this.qitadis=true;
				this.hebandis=true;
				this.gongnengdis=true;
				this.yundongdis=true;
				}
			},
			//牙垫清空
			yadianClear(){
				this.zhengjidis=false;
				this.luoxuandis=false;
				this.qitadis=false;
				this.hebandis=false;
				this.gongnengdis=false;
				this.yundongdis=false;
			},
			//选择颌板
			selectHeBan(value){
				if(this.heban.length==0){
					this.zhengjidis=false;
					this.luoxuandis=false;
					this.qitadis=false;
					this.yadiandis=false;
					this.gongnengdis=false;
					this.yundongdis=false;
				}else{
				this.zhengjidis=true;
				this.luoxuandis=true;
				this.qitadis=true;
				this.yadiandis=true;
				this.gongnengdis=true;
				this.yundongdis=true;
				}
			},
			//颌板清空
			heBanClear(){
				this.zhengjidis=false;
				this.luoxuandis=false;
				this.qitadis=false;
				this.yadiandis=false;
				this.gongnengdis=false;
				this.yundongdis=false;
			},
			//选择其它
			selectQiTa(value){
				if(this.qita.length==0){
					this.zhengjidis=false;
					this.luoxuandis=false;
					this.hebandis=false;
					this.yadiandis=false;
					this.gongnengdis=false;
					this.yundongdis=false;
				}else{
				this.zhengjidis=true;
				this.luoxuandis=true;
				this.hebandis=true;
				this.yadiandis=true;
				this.gongnengdis=true;
				this.yundongdis=true;
				}
			},
			//颌板清空
			qiTaClear(){
				this.zhengjidis=false;
				this.luoxuandis=false;
				this.hebandis=false;
				this.yadiandis=false;
				this.gongnengdis=false;
				this.yundongdis=false;
			},
			//下一步
			nextStepOrder() {
				let planDoctor='';
				if(this.status==2){
					planDoctor=this.planDoctor;
				}else if(this.status==0||this.status==1){
					 planDoctor = this.editor.txt.text();
				}
				//status<2的情况下添加
				if(true){
					if(planDoctor==""){
						this.$alert("请填写治疗目标","提示")
					}else if(this.jituo.length<1){
						this.$alert("请选择基托","提示")
					}else{
						//判断选择的基托是不是不需要基托
						this.ruleForm.planDoctor = planDoctor;
						this.ruleForm.jituo=this.jituo.length>0?this.jituo:[];
						this.ruleForm.zhengji=this.zhengji.length>0?this.zhengji:[];
						this.ruleForm.gongneng=this.gongneng.length>0?this.gongneng:[];
						this.ruleForm.yadian=this.yadian.length>0?this.yadian:[];
						this.ruleForm.qita=this.qita.length>0?this.qita:[];
						this.ruleForm.heban=this.heban.length>0?this.heban:[];
						this.ruleForm.luoxuan=this.luoxuan.length>0?this.luoxuan:[];
						this.ruleForm.huchi=this.yunDong.length>0?this.yunDong:[];
						if(this.zhengji.length==0&&this.gongneng.length==0&&this.yadian.length==0&&this.qita.length==0&&this.heban.length==0&&this.luoxuan.length==0&&this.yunDong.length==0){
							this.$alert("请选择正畸保持器或矫治器","提示")
						}else{
							this.$axios.post("/client/order/addHXPlan", JSON.stringify(this.ruleForm), {
								headers: {
									"Content-type": "application/json"
								}
							}).then(res => {
								this.$store.commit("setHxStepIndex", 3);
								if(res.data.code==200){
									// this.$alert("添加成功","提示")
								}
								//设置返回路径
								this.$store.commit("setBackLocation", "/hxmain/hxorder/hxordertwo");
								this.$router.push("/hxmain/hxorder/hxorderthree");
							}).catch(err => {
								this.$message.error("添加失败");
							})
						}
						
					}
					
				}else{//status==2
					
				}
				
			},
			
			//同意
			tongyi() {
				 this.$confirm('确定同意吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
				          this.$axios.get("/client/order/agreeOrder", {
				          	params: {
				          		oid: this.ruleForm.oid
				          	}
				          }).then(res => {
				          	if (res.data.code == 200) {
				          		
				          		this.$alert("你已经同意","提示");
				          		//根据oid查询目标列表
				          		this.$axios.get("/client/order/queryGoals", {
				          			params: {
				          				oid: this.ruleForm.oid
				          			}
				          		}).then(res => {
				          			let orderInfo = res.data.data;
									
				          			this.zhiLiaoMuBiao = orderInfo.goals;
				          			this.showTongyi=false;
				          			this.showWenzi=orderInfo.showWenzi;
				          		}).catch(err => {
				          			this.$alert("系统出现异常,请联系管理员!");
				          		});
				          		this.editor1.txt.text("");
				          	}
				          
				          }).catch(err => {
				          	this.$message({
				          		type: "error",
				          		message: "请联系管理员",
				          		duration: 1000,
				          		offset: 300
				          	});
				          	
				          });
						  
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '取消了同意操作'
				          });          
				        });
				
			},
			//不同意
			notongyi() {
				 this.$confirm('确定不同意吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
				          let ins = this.editor1.txt.text();
				          if (ins == "") {
				          	this.$alert('修改意见不能为空', '提示', {
				          		confirmButtonText: '确定',
				          		callback: action => {
				          			// this.$router.push("/ysmmain/ysmorder/ysmorderone");
				          		 
				          		}
				          	});
				          } else {
				          	this.$axios.get("/client/order/disagreeOrder", {
				          		params: {
				          			oid: this.ruleForm.oid,
				          			mubiao: ins
				          		}
				          	}).then(res => {
				          		if (res.data.code == 200) {
				          
				          			this.$alert('修改意见成功,请到第8步校核订单后提交订单', '提示', {
				          				confirmButtonText: '确定',
				          				callback: action => {
				          					//根据oid查询目标列表
				          					this.$axios.get("/client/order/queryGoals", {
				          						params: {
				          							oid: this.ruleForm.oid
				          						}
				          					}).then(res => {
				          						let orderInfo = res.data.data;
				          						this.zhiLiaoMuBiao = orderInfo.goals;
												this.showTongyi=false;
												this.showWenzi=orderInfo.showWenzi;
				          						
				          					}).catch(err => {
				          						this.$alert("系统出现异常,请联系管理员!");
				          					});
				          					this.editor1.txt.text("");
				          					
				          				}
				          			});
				          
				          		}
				          
				          	}).catch(err => {
				          		this.$alert('请联系管理员', '提示', {
				          			confirmButtonText: '确定',
				          			callback: action => {}
				          		});
				          	});
				          }
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '取消不同意操作'
				          });          
				        });
				
			},
			//返回
			back(){
				this.$store.commit("setHxStepIndex",1);
				this.$router.push("/hxmain/hxorder/hxorderone");
			},
		},
		mounted: function() {
			
			let oid = sessionStorage.getItem("oid");
			this.ruleForm.oid=oid;
			
			this.editor = new E('#zlcon');
			let _this=this;
			this.editor.config.onchange=function(e){
				var reg = /<[^<>]+>/g; //去标签
				var value = e.replace(reg, "");
				      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
				    
					 let n= value.length; //文字长度显示
					
					 if(n<=110){
						_this.TiLength=n;
						_this.planDoctor=e;
						
					 }else{
						 _this.$nextTick(() => {
						         _this.editor.txt.text(_this.planDoctor);
						 }); 
					 }
					
			}
			this.editor.config.menus = [
				'head', // 标题
				'bold', // 粗体
				'fontSize', // 字号
				'fontName', // 字体
				'italic', // 斜体
				'underline', // 下划线
				'strikeThrough', // 删除线
				'foreColor', // 文字颜色
				// ‘backColor’, // 背景颜色
				// ‘link’, // 插入链接
				// ‘list’, // 列表
				// ‘justify’, // 对齐方式
				// ‘quote’, // 引用
				// ‘emoticon’, // 表情
				// ‘image’, // 插入图片
				// ‘table’, // 表格
				// ‘video’, // 插入视频
				// ‘code’, // 插入代码
				// ‘undo’, // 撤销
				// ‘redo’ // 重复
			]
			this.editor.create();
			
			
			
			if(this.status==2){
				// console.log(this.status);
				// this.editor = new E('#zlcon')
				// this.editor.create();
			}else{
			this.editor1 = new EE('#zlcon1')
			let _this=this;
			let ins="";
			this.editor1.config.onchange=function(e){
				var reg = /<[^<>]+>/g; //去标签
				var value = e.replace(reg, "");
				      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
				    
					 let n= value.length; //文字长度显示
					 if(n<35){
						 _this.showBTYWarn=false;
						_this.BuTongYiwordNumber=n;
						ins=e;
						
					 }else if(n==35){
						 _this.showBTYWarn=true;
						 _this.BuTongYiwordNumber=n;
						 ins=e;
					 }
					 else{
						 _this.$nextTick(() => {
								_this.showBTYWarn=true;
						         _this.editor1.txt.text(ins);
						 }); 
					 }
					
				
			}
			this.editor1.config.menus = [
				'head', // 标题
				'bold', // 粗体
				'fontSize', // 字号
				'fontName', // 字体
				'italic', // 斜体
				'underline', // 下划线
				'strikeThrough', // 删除线
				'foreColor', // 文字颜色
				// ‘backColor’, // 背景颜色
				// ‘link’, // 插入链接
				// ‘list’, // 列表
				// ‘justify’, // 对齐方式
				// ‘quote’, // 引用
				// ‘emoticon’, // 表情
				// ‘image’, // 插入图片
				// ‘table’, // 表格
				// ‘video’, // 插入视频
				// ‘code’, // 插入代码
				// ‘undo’, // 撤销
				// ‘redo’ // 重复
			]
			this.editor1.create();
			}
			this.ruleForm.oid = sessionStorage.getItem("oid");
			sessionStorage.setItem("location","/hxmain/hxorder/hxordertwo")
			this.$store.commit("setHxStepIndex",2);
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//设置返回路径
			this.$store.commit("setBackLocation", "/hxmain/hxorder/hxorderone");
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
		},
		created: function() {
			
			//如果是编辑订单要获取oid的值
				let oid = sessionStorage.getItem("oid");
				// console.log(oid);
				if (oid != "undefined" && oid != '' && oid != null && oid != undefined) {
					this.ruleForm.oid = oid;
					//根据oid查询订单信息
					this.$axios.get("/client/order/getOrderInfo", {
						params: {
							oid: this.ruleForm.oid
						}
					}).then(res => {
						let orderInfo = res.data.data;
						this.status = orderInfo.status;
						
						// if(this.status<2){
						// 	this.editor = new E('#zlcon')
						// 	this.editor.create();
						// 	//治疗目标
						// 	this.editor.txt.text(orderInfo.plan.planDoctor)
						// }
						
						
						/* this.editor = new E('#zlcon');
						this.editor.config.onchange=function(e){
							console.log(e);
							var reg = /<[^<>]+>/g; //去标签
							var value = e.replace(reg, "");
							      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
							    
								 let n= value.length; //文字长度显示
								 let c;
								 if(n<=10){
									document.getElementById("num").innerHTML=n;
									c=e;
									this.planDoctor=e;
									
								 }else{
									 this.$nextTick(() => {
									         this.editor.txt.text(c);
									        }); 
								 }
								
							console.log(value.length);
						}
						this.editor.create(); */
						
						this.editor.txt.text(orderInfo.plan.planDoctor);
						
						this.planDoctor=orderInfo.plan.planDoctor;
						
						this.jituo=orderInfo.plan.jituo;
						if(orderInfo.plan.zhengji.length!=0){
							this.zhengji=orderInfo.plan.zhengji;
							this.gongnengdis=true;
							this.yadiandis=true;
							this.qitadis=true;
							this.hebandis=true;
							this.luoxuandis=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.gongneng.length!=0){
							this.gongneng=orderInfo.plan.gongneng;
							this.zhengjidis=true;
							this.yadiandis=true;
							this.qitadis=true;
							this.hebandis=true;
							this.luoxuandis=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.luoxuan.length!=0){
							this.luoxuan=orderInfo.plan.luoxuan;
							this.zhengjidis=true;
							this.yadiandis=true;
							this.qitadis=true;
							this.hebandis=true;
							this.gongneng=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.yadian.length!=0){
							this.yadian=orderInfo.plan.yadian;
							this.zhengjidis=true;
							this.luoxuandis=true;
							this.qitadis=true;
							this.hebandis=true;
							this.gongneng=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.qita.length!=0){
							this.qita=orderInfo.plan.qita;
							this.zhengjidis=true;
							this.luoxuandis=true;
							this.yadiandis=true;
							this.hebandis=true;
							this.gongneng=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.heban.length!=0){
							this.heban=orderInfo.plan.heban;
							this.zhengjidis=true;
							this.luoxuandis=true;
							this.yadiandis=true;
							this.qitadis=true;
							this.gongneng=true;
							this.yundongdis=true;
						}
						if(orderInfo.plan.huchi.length!=0){
							this.yunDong=orderInfo.plan.huchi;
							this.zhengjidis=true;
							this.luoxuandis=true;
							this.yadiandis=true;
							this.qitadis=true;
							this.gongneng=true;
							this.hebandis=true;
						}
						
						if(this.status==2){
							//根据oid查询目标列表
							this.$axios.get("/client/order/queryGoals", {
								params: {
									oid: this.ruleForm.oid
								}
							}).then(res => {
								let orderInfo1 = res.data.data;
								this.zhiLiaoMuBiao = res.data.data.goals;
								this.showTongyi=orderInfo1.showTongyi;
								this.showWenzi=orderInfo1.showWenzi;
							}).catch(err => {
								this.$alert("系统出现异常,请联系管理员!");
							});
						}
						
						
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					});
			
			
				} else {
					this.$alert('请填写患者信息', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.push("/hxmain/hxorder/hxorderone");
						}
					});
			
				}
				
				
			},
		destroyed:function(){
				let planDoctor='';
				if(this.status==2){
					planDoctor=this.planDoctor;
				}else if(this.status==0||this.status==1){
					 planDoctor = this.editor.txt.text();
				}
				//status<2的情况下添加
				if(true){
					
						//判断选择的基托是不是不需要基托
						this.ruleForm.planDoctor = planDoctor;
						this.ruleForm.jituo=this.jituo.length>0?this.jituo:[];
						this.ruleForm.zhengji=this.zhengji.length>0?this.zhengji:[];
						this.ruleForm.gongneng=this.gongneng.length>0?this.gongneng:[];
						this.ruleForm.yadian=this.yadian.length>0?this.yadian:[];
						this.ruleForm.qita=this.qita.length>0?this.qita:[];
						this.ruleForm.heban=this.heban.length>0?this.heban:[];
						this.ruleForm.luoxuan=this.luoxuan.length>0?this.luoxuan:[];
						this.ruleForm.huchi=this.yunDong.length>0?this.yunDong:[];
						
							this.$axios.post("/client/order/addHXPlan", JSON.stringify(this.ruleForm), {
								headers: {
									"Content-type": "application/json"
								}
							}).then(res => {
								/* this.$store.commit("setHxStepIndex", 3); */
								if(res.data.code==200){
									// this.$alert("添加成功","提示")
								}
								//设置返回路径
								/* this.$store.commit("setBackLocation", "/hxmain/hxorder/hxordertwo");
								this.$router.push("/hxmain/hxorder/hxorderthree"); */
							}).catch(err => {
								this.$message.error("添加失败");
							})
						
						
					
					
				}else{//status==2
					
				}
			}
			
	}
</script>

<style scoped="scoped">
	.hx-order-two {
		width: 80%;
		margin: 0px auto;
		display: flex;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom:10px;
		justify-content: space-around;
	}
	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left {
		
		width: 35rem;
		padding-right: 40px;
	}
	
	.left table tr td:nth-child(1){
		padding-right: 30px;
	}
	/* .left div {
		width: 30rem;
		 display: flex;
		justify-content: space-between;
		
	}
	.left div span{
		display: inline-block;
		width: 220px;
		border: 1px solid green;
		text-align: right;
	}
	.left .el-select{
		padding-left: 50px;
		border: 1px solid green;
		float: right;
	} */

	#zlcon div {
		z-index: 0 !important;
	}

	#zlcon1 ,#zlcon{
		z-index: 0 !important;
	}
	

	/* 右边 */
	.right {
		/* position: relative;
		left: 9.55rem; */
		width: 560px;
	}

	/* 按钮 */
	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
	#ins{
		color: red;
		margin-bottom: 13px;
	}
	#caiye a{
		color: #0D8EA4;
		font-weight: bold;
		text-decoration: underline;
	}
	.wordNumber {
		text-align: right;
		position: relative;
		left: 235px;
	  }
	  .ccc{
		  background-color: #F0F0EE;
	  }
</style>
